import * as React from "react"
import { useTranslation } from "react-i18next"
import LocalizationConstant from "../../../../i18n/LocalizationConstant"

export function ThankYouContent() {
  const { t } = useTranslation()

  return (
    <>
      <h3 className="text-center mt-5">{t(LocalizationConstant.THANK_YOU)}</h3>
      <p className="text-center">
        {t(LocalizationConstant.THANK_YOU_FOR_PARTICIPATING)}
      </p>
    </>
  )
}
