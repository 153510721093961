import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../hooks/useAppSelector"
import { usePageGuid } from "../../../hooks/usePageGuid"
import LocalizationConstant from "../../../i18n/LocalizationConstant"
import { WhitePaddedWrapper } from "../../utils/WhitePaddedWrapper"
import { Title } from "../../shared/Title"
import { EventInformation } from "./components/EventInformation"
import PageType from "../../../enums/PageType"

export function ParticipantPage() {
  const { t } = useTranslation()
  const { currentPeepo } = useAppSelector(state => state.guid)
  usePageGuid(PageType.PARTICIPANT)

  if (currentPeepo === undefined) {
    return <></>
  }

  return (
    <>
      <Title
        title={t(LocalizationConstant.PARTICIPATION_TITLE)}
        subtitles={[
          t(LocalizationConstant.HOME_DESCRIPTION_ONE),
          t(LocalizationConstant.HOME_DESCRIPTION_TWO),
        ]}
      />
      <WhitePaddedWrapper>
        <EventInformation />
      </WhitePaddedWrapper>
    </>
  )
}
