import * as React from "react"
import { JoinNowForm } from "./JoinNowForm"
import { useAppSelector } from "../../../../hooks/useAppSelector"
import { ThankYouContent } from "./ThankYouContent"
import { EventInfo } from "../../../shared/EventInfo"
import { ParticipantTitle } from "./ParticipantTitle"
import { ParticipantList } from "../../../shared/ParticipantList"

export function EventInformation() {
  const { successfullySubmitted } = useAppSelector(
    state => state.participationPage
  )

  return (
    <>
      <ParticipantTitle/>
      <EventInfo/>
      <ParticipantList showFinishedVersion={false}/>
      {successfullySubmitted ? <ThankYouContent /> : <JoinNowForm />}
    </>
  )
}
