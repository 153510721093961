import { PageProps } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/Layout"
import { ParticipantPage } from "../components/pages/participant-page/ParticipantPage"
import SEO from "../components/utils/SEO"
import LocalizationConstant from "../i18n/LocalizationConstant"

export default function PeepoPage(props: PageProps) {
  const { t } = useTranslation()
  return (
    <Layout pageProps={props}>
      <SEO title={t(LocalizationConstant.PARTICIPATION_PAGE_TITLE)}/>
      <ParticipantPage />
    </Layout>
  )
}
