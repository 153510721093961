import * as React from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import TestIDConstants from "../../../../constants/TestIDConstants"
import LocalizationConstant from "../../../../i18n/LocalizationConstant"
import { setParticipationSuccessful } from "../../../../store/participationPageSlice"
import { useAppDispatch } from "../../../../hooks/useAppDispatch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle } from "@fortawesome/free-regular-svg-icons"
import { colorConfig } from "../../../../config/colorConfig"
import { useAddFriendMutation } from "../../../../services/peepo"
import { useAppSelector } from "../../../../hooks/useAppSelector"
import { useEffect } from "react"

export function JoinNowForm() {
  const { t } = useTranslation()
  const [addFriend, { data }] = useAddFriendMutation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { currentGuid } = useAppSelector(state => state.guid)
  const dispatch = useAppDispatch()

  // if we see even just one error, trigger the bootstrap styles for validation
  const formClassName =
    Object.keys(errors).length > 0
      ? "needs-validation was-validated"
      : "needs-validation"

  const handleOnSubmit = (formValues: { name: string }) => {
    addFriend({
      name: formValues.name,
      guid: currentGuid
    })
  }

  useEffect(() => {
    if (data === true) {
      dispatch(setParticipationSuccessful(true))
    }
  }, [data])

  return (
    <>
      <h3 className="text-center">{t(LocalizationConstant.JOIN_NOW)}</h3>
      <div className="container">
        <div className="row">
          <div className="col">
            <form
              data-testid={TestIDConstants.JOIN_NOW_FORM}
              onSubmit={handleSubmit(handleOnSubmit)}
              className={formClassName}
              noValidate
            >
              <div className="row">
                <div className="col-12 p-2">
                  <label
                    htmlFor={TestIDConstants.FIRST_NAME}
                    className="form-label required"
                  >
                    {t(LocalizationConstant.NAME)}
                  </label>
                  <div className="position-relative">
                    <FontAwesomeIcon
                      className="position-absolute input-icon"
                      color={colorConfig.peepoGray}
                      icon={faUserCircle}
                      size="1x"
                    />
                    <input
                      required={true}
                      type="text"
                      data-testid={TestIDConstants.FIRST_NAME}
                      className="input-content form-control"
                      placeholder={t(LocalizationConstant.YOUR_NAME)}
                      {...register("name", { 
                        required: true})}
                    />
                  </div>
                  <div
                    data-testid={TestIDConstants.PLEASE_PROVIDE_FIRST_NAME}
                    className="invalid-feedback"
                  >
                    {t(LocalizationConstant.PLEASE_PROVIDE_YOUR_FIRST_NAME)}
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value={t(LocalizationConstant.PARTICIPATE).toString()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
