import * as React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../../hooks/useAppSelector"
import LocalizationConstant from "../../../../i18n/LocalizationConstant"

export function ParticipantTitle() {
  const { currentPeepo } = useAppSelector(state => state.guid)
  const { t } = useTranslation()

  if (currentPeepo === undefined) {
    return <></>
  }

  const { name } = currentPeepo
  return (
    <h2 className="text-center">
      <span className="text-primary">
        {name}
        {t(LocalizationConstant.PLANNED_COMMA)}
      </span>{" "}
      <span>{t(LocalizationConstant.HAS_PLANNED_THE_FOLLOWING)}</span>
    </h2>
  )
}
